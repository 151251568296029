<template>
  <div class="welcome">
    <h1>{{ msg }}</h1>
    <p>
      SuperMIUI，一个让MIUI更好用的全量级MIUI Xposed插件。<br>
      这是一个Xposed模块，
      <a href="https://wwu.lanzouv.com/b02dwg87e" target="_blank" rel="noopener">点此下载</a>
      (提取码:gulm)。<br>
      建议搭配LSPosed使用。<a href="https://github.com/LSPosed/LSPosed" target="_blank" rel="noopener">点此下载LSPosed</a>
    </p>
    <h3>作者：ZQDesigned</h3>
    <ul>
      <li><a href="https://www.coolapk.com/u/3559333" target="_blank" rel="noopener">酷安</a></li>
      <li><a href="https://github.com/ZQDesigned" target="_blank" rel="noopener">GitHub</a></li>
      QQ：2990918167  微信：GitHubZQDesigned666
    </ul>
    <h3>使用方法</h3>
    <p>
      <red>注意：本模块仅支持MIUI系统，执行以下操作前，请先确保您已经安装并激活了LSPosed。</red><br>
      1.从上方链接或QQ频道或App Share下载本模块并安装。<br>
      2.打开LSPosed，启用本模块并勾选推荐作用域。<red>（当前版本的作用域为：电话服务，系统更新，系统框架，屏幕录制，壁纸）</red><br>
      3.重启手机以应用模块对系统框架的操作。<br>
      4.<red>（划重点）</red>在<red>LSPosed管理器</red>中打开模块设置页面（平板用户和手机用户打开的操作可能不太相同，请以自己的设备情况为准）。<br>
      首次打开会提示您购买卡密并使用卡密激活模块，您可以点击“打开卡密购买页面”购买卡密，也可以直接在本站下方友情链接中找到卡密购买页面。<br>
      <red>注意：如果您下单后无法通过得到的订单号查询到卡密，则可能发生了掉单，我们建议您优先联系智云卡平台官方客服，提供付款订单等信息进行处理！</red><br>
      5.正确激活模块后，请手动重启一次作用域App（在LSPosed管理器中长按所有作用域内的App条目，选择“重启应用”即可）以应用全部HOOK内容。<br>
      6.重启后，您可以在系统更新App右上角的菜单中看到“SuperMIUI-设置”选项，点击即可进入模块主页面，也可以在系统桌面看到模块App图标，均可进入模块主页面。<br>
      偷渡方法：下载系统包后，请使用系统更新自带的手动选择安装包功能进行安装。<br>
      <red>注意：录屏进化部分功能，在您成功使用卡密激活模块后默认开启，激活过期之前始终可用。</red>
    </p>
    <h3>已支持的功能</h3>
    <ul>
      <li>屏蔽更新检测</li>
      <li>无视账号权限获取更新链接</li>
    </ul>
    <h3>已包含</h3>
    <ul>
      <li>MIUI录屏进化</li>
      <li>MIUI更新进化</li>
    </ul>
    <h3>未来将会支持的功能</h3>
    <ul>
      <li>降级卡刷</li>
      <li>直接使用系统更新来处理获取到的更新包链接</li>
      <li>降级卡刷时伪保数据</li>
    </ul>
    <h3>友情链接</h3>
    <p>
      SuperMIUI服务器赞助商：<a href="https://miui.511i.cn" target="_blank" rel="noopener">骁亿@miui.511i.cn</a><br>
      合作人：<a href="https://www.coolapk.com/u/1189245" target="_blank" rel="noopener">酷安@迷璐</a><br>
      模块激活码购买：<a href="https://v1.zhiyunka.com/links/484AC59C" target="_blank" rel="noopener">发卡网</a>（如果您无法通过支付宝支付，请进群联系群主）<br>
      用户交流群（群号：<a href="https://qm.qq.com/cgi-bin/qm/qr?k=NYvRWHobyHLhezXfyEcONPBTUf9oFR8T&jump_from=webapi&authKey=VdLqV0nbNMP9YcB/zyA2xLw3ILXXHinl8lGkw12hJadOMGmgde3I7ftgK/9swaDV" target="_blank" rel="noopener">795320988</a>）
    </p>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
red {
  color: red;
}
</style>